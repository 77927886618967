import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// Frameworks
import {
  Box,
  Button,
  Typography,
  Modal,
  Fade,
} from "@mui/material";

import { useTranslation } from "react-i18next";

const CreateAccount = function () {
  const history = useHistory();
  const [t] = useTranslation(["tradingRobots"]);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={4}
          py={1}
          width={300}
          height={470}
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color="white"
          textAlign="center"
          lineHeight="1.2"
          position="relative"
        >
          <HighlightOffIcon sx={{position: "absolute", top: '10px', right: "10px", cursor:"pointer"}} onClick={()=>{history.push("/")}} />
          <h2>{t("CreateAccount.openNew")}<br/>{t("CreateAccount.mt4Account")}</h2>
          <Button
            sx={{ my: 2, fontSize: "16px"}}
            variant="contained"
            size="large"
            href="https://go.fpmarkets.com/visit/?bta=37291&nci=5678"
            target="_blank"
          >
            {/*{t("CreateAccount.investmentsFrom25")}<br/>*/}{t("CreateAccount.brokerFP")}
          </Button>
          <Button
            sx={{ my: 2, fontSize: "16px"}}
            variant="contained"
            size="large"
            href="https://go.vantagefx.com/visit/?bta=55092&nci=10826"
            target="_blank"
          >
            {/*{t("CreateAccount.investmentsFrom500")}<br/>*/}{t("CreateAccount.brokerVantage")}
          </Button> 
          <Box mt={3}>
            <Typography variant="body1" color="secondary" fontWeight={500}>
              {t("CreateAccount.haveAlreadyAccount")}
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                textDecorationLine: 'underline'
              }}
              component="a"
              referrerPolicy="no-referrer"
              onClick={()=>{history.push("/tradingrobots/LinkAccount")}}
              >
              {t("CreateAccount.linkAccountHere")}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateAccount;
