import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

interface AutoLogOutProps {
  idleMinutes: Number;
}

/**
 * Component used to add an auto log out to the app.
 * @example
 * // Log out after 60 minutes.
 * <AutoLogOut idleMinutes={60} />
 */
const AutoLogOut = ({ idleMinutes = 120 }: AutoLogOutProps) => {
  const history = useHistory();
  const [idleTime, setIdleTime] = useState(0);

  /**
   * Handler that will +1 to idleTime every time it gets executed
   */
  const handleIdle = () => {
    setIdleTime((prevIdleTime) => prevIdleTime + 1);
  };

  /**
   * Handle user activity
   */
  const handleActivity = () => {
    setIdleTime(0);
  };

  // Effect to setup idle handler & event listeners
  useEffect(() => {
    const id = setInterval(handleIdle, 60000);
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    // Cleanup function
    return () => {
      clearInterval(id);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, []);
  // effect that will check if the user reached max idle time
  useEffect(() => {
    if (idleTime === idleMinutes) {
      localStorage.removeItem("user_id");
      history.push("/");
    }
  }, [idleTime, idleMinutes, history]);

  return null;
};

export default AutoLogOut;
