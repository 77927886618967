import { useState } from "react";
import PropTypes from "prop-types";
// Frameworks
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { loadRules } from "store/reducers/builder.reducer";
import { loadStrategy } from "store/reducers/strategy.reducer";
import { show } from "store/reducers/notifications.reducer";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Assets
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TimelineIcon from "@mui/icons-material/Timeline";
// Custom components
import ProfitRiskBox from "components/common/ProfitRiskBox";
import { format } from "date-fns";
import customAxios from "lib/axios";
import useStrategy from "hooks/useStrategy";

/**
 * Component that represents a strategy card
 * @param {Object} props
 * @param {Number} props.id
 * @param {String} props.title
 * @param {String} props.image
 * @param {String} props.creationDate
 * @param {Number} props.risk
 * @param {Number} props.profit
 * @param {String} props.asset
 * @param {String} props.timeframe
 * @param {Boolean} props.isSession
 *
 * @component
 */
const StrategyCard = function ({
  title,
  creationDate,
  image,
  profit,
  risk,
  timeframe,
  asset,
  isSession,
  id,
}) {
  const { load, working } = useStrategy();
  const [loaded, setLoaded] = useState(false);
  const [loadRedirect, setLoadRedirect] = useState("/builder");
  const [t] = useTranslation(["dashboard", "errors"]);
  const dispatch = useDispatch();

  const sessionLoad = async (url) => {
    const userId = localStorage.getItem("user_id");
    try {
      const res = await customAxios(`session/${userId}`);
      dispatch(
        loadRules({
          openRules: res.data.load_open_scenario,
          closeRules: res.data.load_close_scenario,
        })
      );
      dispatch(
        loadStrategy({
          origin: res.data.origin,
          id: res.data.id,
          isSaved: false,
        })
      );
      setLoadRedirect(url);
      setLoaded(true);
    } catch (error) {
      dispatch(show({ type: "error", msg: t("errors:loadStrategy") }));
    }
  };

  const strategyLoad = async (url) => {
    try {
      await load("M", id);
      setLoadRedirect(url);
      setLoaded(true);
    } catch (error) {
      dispatch(show({ type: "error", msg: t("errors:loadStrategy") }));
    }
  };

  const handleLoad = (url) => () => {
    if (isSession) {
      sessionLoad(url);
    } else {
      strategyLoad(url);
    }
  };

  if (loaded) {
    return <Redirect to={loadRedirect} push />;
  }

  return (
    <Box
      sx={{
        margin: { sm: "0 8px" },
      }}
      border={1}
      my={1}
      borderColor="#F4F4F7"
      borderRadius="5px"
      bgcolor="#FFFFFF"
      overflow="hidden"
      whiteSpace="nowrap"
      width="100%"
      display="flex"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box
        position="absolute"
        sx={{
          opacity: 0,
          transition: (theme) => theme.transitions.create("opacity"),
          "&:hover": {
            opacity: 1,
          },
        }}
        display="flex"
        height="100%"
        flexDirection="column"
        width="100%"
        px={1}
        alignItems="center"
        justifyContent="center"
        bgcolor="rgba(235, 235, 242, 0.8)"
        zIndex="2"
        border={1}
        borderColor="#EBEBF2"
      >
        <LoadingButton
          fullWidth
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={handleLoad("/builder")}
          loading={working}
          loadingPosition="end"
        >
          {t("builder")}
        </LoadingButton>
        <Box my={1} />
        <LoadingButton
          fullWidth
          variant="contained"
          endIcon={<TimelineIcon />}
          onClick={handleLoad("/validation")}
          loading={working}
          loadingPosition="end"
        >
          {t("validate")}
        </LoadingButton>
      </Box>
      <Box p={2} pb={0} display="flex" flexDirection="column" width="100%">
        <Typography variant="h6" color="textPrimary" noWrap gutterBottom>
          {title}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {format(new Date(creationDate), "dd MMM yyyy HH:mm")}
        </Typography>
      </Box>
      <Box width="100%" position="relative" height="40%">
        <img src={image} width="100%" height="100%" alt="" />
        <Box position="absolute" top="30%">
          <ProfitRiskBox
            profit={profit}
            risk={risk}
            profitBorderRadius="0"
            riskBorderRadius="0 10px 10px 0"
          />
        </Box>
      </Box>
      <Box
        bgcolor="#F7F7FA"
        p={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Timeframe:{" "}
          <Typography component="span" variant="body2" color="textPrimary">
            {timeframe}
          </Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("asset")}:{" "}
          <Typography component="span" variant="body2" color="textPrimary">
            {asset}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

StrategyCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  creationDate: PropTypes.string,
  timeframe: PropTypes.string,
  asset: PropTypes.string,
  image: PropTypes.string,
  profit: PropTypes.number,
  risk: PropTypes.number,
  isSession: PropTypes.bool,
};

StrategyCard.defaultProps = {
  id: null,
  title: "Untitled Strategy",
  timeframe: "Undefined",
  asset: "Undefined",
  creationDate: new Date().toISOString(),
  image: "",
  profit: 0,
  risk: 0,
  isSession: false,
};

export default StrategyCard;
