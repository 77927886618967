import Chart from "react-apexcharts";
import { formatNumberWithLocale } from "lib/helpers";
import { useTranslation } from "react-i18next";

type DonutChartProps = {
  /** Data that donut will display in numbers */
  series: Array<number>;
  /** Labels of the data displayed */
  labels: Array<string>;
  /** Currency associated to the data */
  currency?: string;
  /** Donut portions colors */
  colors?: Array<string>;
  /** Size of the radius in percentaje  */
  radiusSize?: string;
  /** Format the series values into currency */
  format?: boolean;
  enableLabels?: boolean;
  enableTooltips?: boolean;
};

/**
 * Display a Donut Chart
 *
 * [ApexCharts API](https://apexcharts.com/docs/chart-types/pie-donut/)
 *
 * @component
 */
const DonutChart = function ({
  series,
  labels,
  currency = "EUR",
  colors = ["#25AAE2", "#0C419D", "#E5AA17", "#11CC9A", "#E20E7C"],
  format = false,
  enableTooltips = false,
  enableLabels = true,
  radiusSize = "85%",
}: DonutChartProps): JSX.Element {
  const { i18n } = useTranslation();
  return (
    <Chart
      type="donut"
      series={series}
      options={{
        colors,
        chart: {
          animations: { enabled: false },
          width: "100%",
          height: "100%",
          selection: { enabled: false },
        },
        labels,
        dataLabels: {
          enabled: false,
          style: { fontFamily: "poppins" },
          dropShadow: { enabled: false },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: enableLabels,
                name: {
                  fontFamily: "poppins",
                },
                value: {
                  formatter: (val) =>
                    formatNumberWithLocale(
                      parseFloat(val),
                      2,
                      i18n.language,
                      format ? "currency" : "decimal",
                      currency
                    ),
                  // if (format) {
                  //   return `${parseFloat(val).toLocaleString(undefined, {
                  //     maximumFractionDigits: 2,
                  //     minimumFractionDigits: 2,
                  //   })} ${currency}`;
                  // }
                  // return `${val} ${currency}`;
                },
              },
              size: radiusSize,
            },
          },
        },
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: enableTooltips,
          y: {
            formatter: (val) =>
              formatNumberWithLocale(
                val,
                2,
                i18n.language,
                format ? "currency" : "decimal",
                currency
              ),
          },
        },
      }}
    />
  );
};

export default DonutChart;
