import { createSlice } from "@reduxjs/toolkit";
import OpenRule from "models/OpenRule";
import CloseRule from "models/CloseRule";
import { buildElementFromAPI } from "lib/helpers";
import Element from "models/Element";
import undoable from "redux-undo";

/**
 * Search the SHOOT_SIGNAL parameter and see if i'ts in trigger mode.
 */
function isTrigger(element: Element) {
  const parameter = element.params.find((p) => p.name === "SHOOT_SIGNAL");
  // If the element hasn't the SHOOT_SIGNAL param or I'ts in trigger mode, return true.
  if (!parameter || parameter.value === "1") return true;
  return false;
}

interface BuilderState {
  openRuleCount: number;
  closeRuleCount: number;
  rules: Array<OpenRule | CloseRule>;
}

const initialState: BuilderState = {
  openRuleCount: 0,
  closeRuleCount: 0,
  rules: [],
};

export const builderSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    resetBuilder: () => initialState,
    loadRules: (state, action) => {
      const { openRules, closeRules } = action.payload;
      const newState = { ...initialState }; // Creamos el nuevo estado.
      newState.rules = openRules.map(
        (r: any) =>
          new OpenRule({
            ...r,
            elements: r.elements.map((e: any) =>
              buildElementFromAPI(e, "open")
            ),
          })
      );
      newState.rules = newState.rules.concat(
        closeRules.map(
          (r: any) =>
            new CloseRule({
              ...r,
              elements: r.elements.map((e: any) =>
                buildElementFromAPI(e, "close")
              ),
            })
        )
      );

      return newState;
    },
    addOpenRule: (state, action) => {
      state.rules.push(
        new OpenRule({
          id: action.payload.idv4,
          number: state.openRuleCount,
          openBuys: action.payload.buys,
          openSells: action.payload.sells,
        })
      );
      state.openRuleCount += 1;
    },
    addCloseRule: (state, action) => {
      state.rules.push(
        new CloseRule({
          id: action.payload.idv4,
          number: state.closeRuleCount,
          closeBuys: action.payload.buys,
          closeSells: action.payload.sells,
        })
      );
      state.closeRuleCount += 1;
    },
    addElement: (state, action) => {
      const { ruleId, element, parameters } = action.payload;
      const ruleIndex = state.rules.findIndex((r) => r.id === ruleId); // Find target drop Rule
      const newRule = { ...state.rules[ruleIndex] };
      // If the rule has the element disabled, don't add it.
      if (newRule.disabledElements.includes(element.element_id.toString())) {
        return state;
      }

      // Create the element
      const newElement = buildElementFromAPI(
        { ...element, params: parameters },
        newRule.type
      );
      newRule.elements.push(newElement); // Add the element to the rule

      // Check if the element is in trigger mode & if true, +1 to rule trigger counter.
      if (isTrigger(newElement)) newRule.triggerCount += 1;
      // If more than 1 trigger & elements inside rule, scenario has errors.
      if (newRule.triggerCount !== 1 && newRule.elements.length > 0) {
        newRule.hasErrors = true;
      } else {
        newRule.hasErrors = false;
      }

      state.rules[ruleIndex] = newRule;

      return state;
    },
    modifyElementShootType: (state, action) => {
      const { ruleId, shootTypeValue } = action.payload;
      const ruleIndex = state.rules.findIndex((r) => r.id === ruleId); //  Search the modified rule index by id.
      const newRule = { ...state.rules[ruleIndex] };
      // If the shootTYPE is 1, add trigger, else remove 1
      if (shootTypeValue === "1") {
        newRule.triggerCount += 1;
      } else {
        newRule.triggerCount -= 1;
      }
      // If more than 1 trigger & elements inside rule, scenario has errors.
      if (newRule.triggerCount !== 1 && newRule.elements.length > 0) {
        newRule.hasErrors = true;
      } else {
        newRule.hasErrors = false;
      }

      state.rules[ruleIndex] = newRule;

      return state;
    },
    deleteRule: (state, action) => {
      const ruleIndex = state.rules.findIndex(
        (rule) => rule.id === action.payload
      );
      if (state.rules[ruleIndex].type === "open") {
        state.openRuleCount -= 1;
      } else {
        state.closeRuleCount -= 1;
      }
      state.rules.splice(ruleIndex, 1);
    },
    deleteElement: (state, action) => {
      const { ruleId, elementNumber } = action.payload;
      const ruleIndex = state.rules.findIndex((r) => r.id === ruleId);
      const newRule = { ...state.rules[ruleIndex] };
      const elementIndex = newRule.elements.findIndex(
        (e) => e.number === elementNumber
      );

      // Check if the element is in trigger mode & if true, -1 to rule trigger counter.
      if (isTrigger(newRule.elements[elementIndex])) newRule.triggerCount -= 1;

      // If more than 1 trigger & elements inside rule, scenario has errors.
      if (newRule.triggerCount !== 1 && newRule.elements.length > 0) {
        newRule.hasErrors = true;
      } else {
        newRule.hasErrors = false;
      }

      newRule.elements.splice(elementIndex, 1);

      state.rules[ruleIndex] = newRule;

      return state;
    },
    configureOpenRule: (state, action) => {
      const {
        ruleId,
        sameType,
        differentType,
        volumeType,
        volume,
        porcentage,
        readingType,
      } = action.payload;
      const ruleIndex = state.rules.findIndex((r) => r.id === ruleId);
      const rule: any = { ...state.rules[ruleIndex] };
      rule.allowSameType = sameType;
      rule.allowDifferentType = differentType;
      rule.openVolType = volumeType;
      rule.volume = volume;
      rule.porcentage = porcentage;
      rule.readingType = readingType;
      state.rules[ruleIndex] = rule;
    },

    configureCloseRule: (state, action) => {
      const { readingType, ruleId } = action.payload;
      const ruleIndex = state.rules.findIndex((r) => r.id === ruleId);
      const rule: any = { ...state.rules[ruleIndex] };
      rule.readingType = readingType;
      state.rules[ruleIndex] = rule;
    },

    activateRule: (state, action) => {
      const { id } = action.payload;
      const ruleIndex = state.rules.findIndex((r) => r.id === id);
      const rule = state.rules[ruleIndex];
      state.rules[ruleIndex] = { ...rule, active: !rule.active };
    },
  },
});

export const {
  addCloseRule,
  addElement,
  addOpenRule,
  deleteElement,
  deleteRule,
  configureOpenRule,
  configureCloseRule,
  modifyElementShootType,
  loadRules,
  resetBuilder,
  activateRule,
} = builderSlice.actions;

const undoableBuilder = undoable(builderSlice.reducer);
export default undoableBuilder;
