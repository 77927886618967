import { v4 as uuidv4 } from "uuid";
import Element from "models/Element";
import Parameter from "models/Parameter";

/**
 * Calcultes percentage
 */
export function percentage(partialValue: number, totalValue: number) {
  const calc = (100 * partialValue) / totalValue;
  return Number.isNaN(calc)
    ? 0
    : calc.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
}

/**
 * Calculate percentage change between 2 numbers
 */
export function percentageChange(oldNumber: number, newNumber: number) {
  const change = (newNumber / oldNumber) * 100;
  return Number.isNaN(change) ? 0 : change.toFixed(2);
}
/**
 * Calculate the average between 2 numbers
 */
export const calculateAverage = (dividend: number, divisor: number) => {
  const calculated = dividend / divisor;
  if (Number.isNaN(calculated)) return 0;
  return calculated;
};

/**
 * Calculate average gain of a Strategy
 * @param gains Gains only
 * @param loses Loses only
 * @param tradesWon Trades Won
 * @param tradesLost Trades Lost
 * @returns Average gain
 */
export function calculateAverageGain(
  gains: number,
  loses: number,
  tradesWon: number,
  tradesLost: number
) {
  const avg = (gains - loses) / (tradesWon + tradesLost);
  return Number.isNaN(avg) ? 0 : avg;
}

/**
 * Buscar valor clave en objeto via string
 * @doc https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
 */
export function getObjectByString(o: any, s: string) {
  try {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; i += 1) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      }
    }
  } catch (e) {
    o = "";
  }
  return o;
}

export function formatNumberWithLocale(
  value: number,
  shownDecimals: number,
  locale: string,
  style: string,
  currency?: string,
  negativeValue: boolean = false
): string {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: shownDecimals,
    currency,
    style,
    currencyDisplay: "narrowSymbol",
  }).format(negativeValue ? value * -1 : value);
}

export function buildDisabledElementsFromAPI(disabledElements: any[]) {
  return disabledElements.map(
    (disabledElement) => disabledElement.disabledElementId || disabledElement
  );
}

export function buildParametersFromAPI(parameters: any, ruleType: string) {
  // TODO: Denfine API types
  return parameters.map(
    (p: {
      defaultOpen: string | null;
      defaultClose: string | null;
      default: string;
      id: any;
      reference: any;
      type: any;
      dropdown_value: any;
      parent: any;
      parent_value: any;
      OnlyExit: any;
      visible: any;
      box_visible: any;
      value: string;
    }) => {
      let defaultParameterValue = "";
      if (p.defaultOpen != null && ruleType === "open") {
        defaultParameterValue = p.defaultOpen;
      } else if (p.defaultClose != null && ruleType === "close") {
        defaultParameterValue = p.defaultClose;
      } else {
        defaultParameterValue = p.default;
      }
      return new Parameter({
        id: p.id,
        name: p.reference,
        displayName: p.reference,
        type: p.type,
        defaultValue: defaultParameterValue,
        value: p.value || defaultParameterValue,
        values: p.dropdown_value,
        parent: p.parent,
        parentValue: p.parent_value,
        onlyExit: p.OnlyExit,
        visible: p.visible,
        boxVisible: p.box_visible,
      });
    }
  );
}

export function buildElementFromAPI(e: any, ruleType: string) {
  // TODO: Denfine API types
  return new Element({
    id: e.element_id,
    number: uuidv4(),
    name: e.element_name,
    image: process.env.PUBLIC_URL + e.image_url,
    params: buildParametersFromAPI(e.params, ruleType),
    tickAvailable: e.tickAvailable,
    disabledElements: buildDisabledElementsFromAPI(e.disabledElements),
  });
}

/**
 * Draw operation in tradingview chart
 */
export const drawOperation = (
  widget: any,
  isBuy = true,
  text: string,
  time: number,
  price: number,
  volume: number
) => {
  let direction = ""; // Variable para almacenar la dirección
  if (isBuy) {
    if (text === "Close"){
      direction = "sell";
    }else  {
      direction = "buy";
    }
  } else {
    if (text === "Close"){
      direction = "buy";
    }else  {
      direction = "sell";
    }
  }

  widget
    .activeChart()
    .createExecutionShape()
    .setText(`${text} ${volume}`)
    .setTextColor(isBuy ? "#80A1C1" : "#DF928E")
    .setFont("14px Arial")
    .setDirection(direction)
    .setArrowColor(isBuy ? "#80A1C1" : "#DF928E")   
    .setTime(time)
    .setPrice(price);
};


/**
 * Draw operation trend in tradingview chart
 */
export const drawOperationTrend = (
  widget: any,
  a1: number,
  b1: number,
  price1: number,
  price2: number
) => {
  widget.activeChart().createMultipointShape(
    [
      { time: a1, price: price1, channel: "open" },
      { time: b1, price: price2, channel: "open" },
    ],
    {
      shape: "trend_line",
      //lock: true,
      disableSelection: true,
      disableSave: true,
      //disableUndo: true,
      bringToFrontEnabled: true,
    }
  );
};
