import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
// Frameworks
import {
  Box,
  Typography,
  Modal,
  Fade,
  Button,
  Grid
} from "@mui/material";
import Link from '@mui/material/Link';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AlertModal from "components/TradingRobots/AlertModal";
import Card from '@mui/material/Card';
// import AdbIcon from '@mui/icons-material/Adb';
import Checkbox from '@mui/material/Checkbox';
import useAppDispatch from "hooks/useAppDispatch";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
// Custom imports
import { show } from "store/reducers/notifications.reducer";
// Assets
import customAxios from "lib/axios";
import Slider from '@mui/material/Slider';

import robotImg from "../../assets/img/hanoi.png";
import robotImg2 from "../../assets/img/zurich.png";
import robotImg3 from "../../assets/img/selection.png";

// custom progress bar
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  // marginTop: 5,
  marginBottom: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const RobotSelectModal = function (props) {
  const localUserId = localStorage.getItem("user_id");
  const dispatch = useAppDispatch();
  const isUserLoggedIn =
    localUserId && !Number.isNaN(localStorage.getItem("user_id"));
  const [t] = useTranslation(["tradingRobots"]);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedRobot, setSelectedRobot] = useState(0);
  const [selectedRobotItem, setSelectedRobotItem] = useState(0);
  const [marks, setMarks] = useState([]);
  const [sliderValue, setSliderValue] = useState(1);

  const [alertActived, setAlertActived] = useState(false);
  const [agree, setAgree] = useState(false);

  const [robotList, setRobotList] = useState([]);

  useEffect(() => {
    let buffer= new Array();
    props.robotsInfo.forEach(robot => {
      if (buffer.filter(registeredItem=> registeredItem.robotid == robot.robotid).length == 0) {
        buffer.push(robot);
      }
    })
    if (buffer.length == 0) {
      return;
    }
    setRobotList(buffer);
    onSelectedRobot(buffer[0].robotid);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (agree === false) {
      setAlertActived(true);
      return;
    }
    const userId = localStorage.getItem("user_id");
    const robotId = props.robotsInfo[selectedRobotItem].robotid;
    const risklevel = props.robotsInfo[selectedRobotItem].risklevel;
    const userrobot = props.robotsInfo[selectedRobotItem].USER_ROBOT;
    try {
      const respond = await customAxios(`/tradingrobots/addActivedRobot/${userId}/${robotId}/${risklevel}/${userrobot}`);
      if (respond.data.msg === "failed") {
        dispatch(show({ type: "error", msg: t("errors:serverError") }));
      } else {  // Update robots list
        props.activateHandler();
      }
    } catch (error) {
      dispatch(show({ type: "error", msg: t("errors:serverError") }));
    }
  };
  const onSelectedRobot = (robotid) => {
    setSelectedRobot(robotid);
    const buffer = new Array();
    props.robotsInfo.filter(item=>item.robotid==robotid).forEach(item=> {
      buffer.push({value: item.risklevel})
    })
    buffer.sort((a, b) => {
      return a.value-b.value;
    })
    setMarks(buffer);
    onChangeRiskLevel(null, 3, robotid);
  }
  const onChangeRiskLevel = (e, riskLevel, robotid) => {
    setSliderValue(riskLevel); 
    props.robotsInfo.forEach((item, index) => {
      if (item.robotid == robotid && item.risklevel == riskLevel) {
        setSelectedRobotItem(index);
        return;
      }
    })
  }

  const selectImage = (robotName) => {
    if (robotName == 'HANOI') {return robotImg}
    if (robotName == 'ZURICH') {return robotImg2}
    if (robotName == 'TRADEASY SELECCTION') {return robotImg3}
  }


  const robotItemList = robotList.map((curItem, i) => 
      <Card key={i} 
        onClick={()=>onSelectedRobot(curItem.robotid)}
        sx= {{
        display: "flex",                // flexDirection: "column"
        // alignItems: 'center',
        padding: "5px",
        border:1,
        marginRight: "5px",
        marginBottom: "1px",
        cursor: "pointer",
        borderWidth: (curItem.robotid===selectedRobot) ? "3px" : "3px",
        borderColor: (curItem.robotid===selectedRobot) ? "#009900" : "auto",
        color: (curItem.robotid===selectedRobot) ? "#009900" : "auto"
      }}>
      <Box sx={(curItem.robotid===selectedRobot) ? {border:1, borderColor: "#009900", borderRadius: '10px', paddingTop: "7px"} : {border:1, borderRadius: '10px',  paddingTop: "7px"}} width="90px" ml={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
        <Typography sx={{ fontSize: 14 }} color={(curItem.robotid===selectedRobot) ? "#009900" : "text.secondary"} textAlign="center">
        {curItem.robotname}
        </Typography>
        <img src={curItem.image_url} alt="robot" width="40" height="40"/>
        {/* <AdbIcon  sx={{ fontSize: 27 }}/> */}
      </Box>
      <Box ml={1} width="110px" alignSelf="center">
        <Button size="small" sx={{mb:"2px", width:1}} href={curItem.moreinfo_url} target="_blank">{t("selectRobots.moreInfo")}</Button>
        <Button size="small" sx={{mt:"2px", width:1}} href={"https://app.tradeasy.tech/robots"} target="_blank">{t("selectRobots.viewStrategy")}</Button>     
      </Box>
    </Card>
  )
  
  function valuetext(value) {
    return `${value}°C`;
  }  
  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }
  return (
    <>
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={4}
          py={1}
          width={310}
          height={470}
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          color="white"
          textAlign="center"
          lineHeight="1.2"
        >
          <Box
            bgcolor="#1A2B47"
            my={2}
            height={237}
            borderRadius="10px"
            color="white"
            textAlign="center"
            lineHeight="1.2"
            sx={{
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            {robotItemList}
          </Box>
          <Typography textAlign="left">{t("selectRobots.riskLevel")}</Typography>
          <Grid container mt={1}>
            <Grid display="flex" justifyContent="center" alignItems="flex-end" xs={4} pb={2} pr={2} item>
                {/* <img src={Cell} style={{marginTop:"5px"}} height={20} alt="tradEAsy Cell"/> */}            
                <Box bgcolor={props.robotsInfo[selectedRobotItem].risklevel >0 ? "#00b050" : "#25344B"} mx="3px" width={3} height={5}></Box>
                <Box bgcolor={props.robotsInfo[selectedRobotItem].risklevel >1 ? "#70ad47" : "#25344B"} mx="3px" width={3} height={10}></Box>
                <Box bgcolor={props.robotsInfo[selectedRobotItem].risklevel >2 ? "#92d050" : "#25344B"} mx="3px" width={3} height={15}></Box>
                <Box bgcolor={props.robotsInfo[selectedRobotItem].risklevel >3 ? "#ffc000" : "#25344B"} mx="3px" width={3} height={20}></Box>
                <Box bgcolor={props.robotsInfo[selectedRobotItem].risklevel >4 ? "#ff0000" : "#25344B"} mx="3px" width={3} height={25}></Box>
            </Grid>
            <Grid xs={8} item>
              <Box display="flex" justifyContent="space-between" mb="3px">
              <Typography fontSize="14px" >-</Typography>
              <Typography fontSize="14px" >{t("main." + props.robotsInfo[selectedRobotItem].riskname)}</Typography>
              <Typography fontSize="14px" >+</Typography>
              </Box>
            {/* <BorderLinearProgress variant="determinate" value={50} /> */}
            <Slider
              aria-label="Restricted values"
              // sliderValue={sliderValue}
              // valueLabelFormat={valueLabelFormat}
              // getAriaValueText={valuetext}
              value={sliderValue}
              step={null}
              valueLabelDisplay="auto"
              marks={marks}
              min={1}
              max={5}
              onChange={(e, value)=>{onChangeRiskLevel(e, value, selectedRobot)}}
            />
            </Grid>
          </Grid>
          <Grid container ml={1}>
            <Grid xs={6} item>
              <Typography textAlign="left" fontSize="14px" my="3px">{t("selectRobots.expectedProfit")}</Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography textAlign="left" fontSize="14px" my="3px">&nbsp;: &nbsp;{props.robotsInfo[selectedRobotItem].profit}% / {t("selectRobots.month")}</Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography textAlign="left" fontSize="14px" my="3px">{t("selectRobots.expectedRisk")}</Typography>
            </Grid>
            <Grid xs={6} item>
              <Typography textAlign="left" fontSize="14px" my="3px">&nbsp;: &nbsp;{props.robotsInfo[selectedRobotItem].risk}% {t("selectRobots.drawdown")}</Typography>
            </Grid>
          </Grid>
          <Box display="flex" mx={-5} mt={1} justifyContent="center" alignItems="center" fontSize="10px">
            <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} onClick={()=>setAgree(!agree)} />
            <Typography fontSize="12px" mr="2px">
              {t("selectRobots.agreeWith")}
            </Typography>
            <Typography
              component="a"
              color="white"
              fontSize="12px"
              target="_blank"
              referrerPolicy="no-referrer"
              href="https://web.tradeasy.tech/terminos-y-condiciones/"
            >
              {t("selectRobots.termsAndConditions")}
            </Typography>
          </Box>
          <Box display="flex" pb={1} justifyContent="space-between">
            <LoadingButton
              onClick={onSubmit}
              variant="contained"
              sx= {{ width: "120px"}}
            >
              {t("selectRobots.activate")}
            </LoadingButton>
            <LoadingButton
              onClick={props.closeHandler}
              variant="contained"
              sx= {{ width: "120px"}}
            >
              {t("selectRobots.cancel")}
            </LoadingButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
    {alertActived == true && 
      <AlertModal msgtext={t("selectRobots.agreeAlert")}
      alerttype="cancel"
      cancelbtnText={t("main.cancel")}
      cancelHandler={()=>setAlertActived(false)} />
    }
    </>
  );
};

RobotSelectModal.propTypes = {
  activateHandler: PropTypes.func,
  robotsInfo: PropTypes.array,
  closeHandler: PropTypes.func
}

export default RobotSelectModal;