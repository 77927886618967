import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// Frameworks
import {
  Box,
  Button,
  Typography,
  Modal,
  Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const OpenAccount = function () {
  const history = useHistory();
  const [t] = useTranslation(["tradingRobots"]);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={4}
          py={1}
          width={310}
          height={470}
          color="white"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          position="relative"
        >
         <HighlightOffIcon sx={{position: "absolute", top: '10px', right: "10px", cursor:"pointer"}} onClick={()=>{history.push("/")}} />
          
          <Button
            sx={{ my: 3, mt: 5, textAlign: "center"}}
            variant="contained"
            size="large"
            onClick={()=>{history.push("/tradingrobots/CreateAccount")}}
            
          >
          {/*<Button
            sx={{ my: 2, fontSize: "16px"}}
            variant="contained"
            size="large"
            href="https://go.fpmarkets.com/visit/?bta=37291&nci=5678"
            target="_blank"
          >*/}
            <h3>{t("OpenAccount.openNew")}<br/>{t("OpenAccount.mt4Account")}</h3>
          </Button>
          <Button
            sx={{ my: 3, textAlign: "center"}}
            variant="contained"
            size="large"
            onClick={()=>{history.push("/tradingrobots/LinkAccount")}}
          >
            <h3>{t("OpenAccount.linkExisting")}<br/>{t("OpenAccount.mt4Account")}</h3>
          </Button>

          <Typography
              component="a"
              mb={1}
              color="primary"
              fontWeight={700}
              target="_blank"
              referrerPolicy="no-referrer"
              textAlign="center"
              href="https://wa.me/932715804"
            >
              {t("needSupport")}
          </Typography>

        </Box>
      </Fade>
    </Modal>
  );
};

export default OpenAccount;
