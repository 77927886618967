import { useState, useEffect } from "react";

export default function useOrientation(): {
  isDesktop: boolean;
  isLandscape: boolean;
  isPortrait: boolean;
} {
  const [isLandscape, setIsLandscape] = useState(true);
  const handleOrientationChange = (mql: MediaQueryListEvent) => {
    setIsLandscape(mql.matches);
  };

  // Effect to setup listeners & current orientation
  useEffect(() => {
    const landscapeMediaQuery = window.matchMedia("(orientation: landscape)");
    setIsLandscape(landscapeMediaQuery.matches);
    landscapeMediaQuery.addEventListener("change", handleOrientationChange);

    // Cleanup function
    return () => {
      landscapeMediaQuery.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);
  return {
    isDesktop:
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
    isLandscape,
    isPortrait: !isLandscape,
  };
}
