import { useEffect, useState } from "react";
// Frameworks
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Tabs,
  Tab,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { resetBuilder } from "store/reducers/builder.reducer";
import { reset } from "store/reducers/strategy.reducer";
import { percentage } from "lib/helpers";
import { useTranslation } from "react-i18next";

// Assets
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TimelineIcon from "@mui/icons-material/Timeline";

// Custom components
import ProfitRiskBox from "components/common/ProfitRiskBox";
import CustomDonutChart from "components/common/DonutChart";
import LoadingShow from "components/common/LoadingShow";
import StrategyCard from "components/common/StrategyCard";
import ProtectedComponent from "components/common/ProtectedComponent";
import { Link, Redirect } from "react-router-dom";
import { format } from "date-fns";
import useDispatch from "hooks/useAppDispatch";
import useStrategy from "hooks/useStrategy";
import { show } from "store/reducers/notifications.reducer";
import customAxios from "lib/axios";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0, 0, 1),
    },
  },
  chartContainer: {
    [theme.breakpoints.up("lg")]: {
      height: "50%",
    },
  },
  lastStrategyContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "30%",
      borderRadius: "10px 0 0 0",
    },
  },
  dataContainer: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  pieContainer: {
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  legendContainer: {
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  myStrategiesContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "70%",
      borderRadius: "10px 0 0 0",
    },
  },
  strategyContainer: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },

  bottomRow: {
    [theme.breakpoints.up("lg")]: {
      height: "40%",
    },
  },
  leftSummaryContainer: {
    [theme.breakpoints.up("lg")]: {
      borderRadius: "10px 0 0 10px",
      height: "100%",
      width: "50%",
      margin: 0,
    },
  },
  leftContainer: {
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      width: "70%",
      height: "100%",
      margin: theme.spacing(0, 1, 0, 0),
    },
  },
  dashboardRow: {
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
}));

const assetColors = ["#25AAE2", "#0C419D", "#E5AA17", "#11CC9A", "#E20E7C"];
const sharedColors = ["#25AAE2", "#0C419D", "#E5AA17"];

const Dashboard = function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { load, working } = useStrategy();
  const isUserFree = localStorage.getItem("type_user") === "F";
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [summary, setSummary] = useState(null);
  const [sharedStrategies, setSharedStrategies] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [news, setNews] = useState([]);
  const [sortType, setSortType] = useState("popular");
  const [loadDestination, setLoadDestination] = useState("/builder");
  const [loaded, setLoaded] = useState(false);
  const [t, i18n] = useTranslation(["dashboard", "timeframes", "errors"]);

  useEffect(() => {
    const apiCalls = async () => {
      const userId = localStorage.getItem("user_id");
      setLoadingScreen(true);
      try {
        const responses = await Promise.all([
          customAxios(`/strategies/user/${userId}/summary`),
          customAxios("/sharedStrategies/truncatedRow"),
          customAxios(`strategies/user/${userId}`),
          customAxios("/news"),
        ]);
        const [summaryRes, sharedStrategiesRes, strategiesRes, newsRes] =
          responses;
        setSummary(summaryRes.data);
        setSharedStrategies(sharedStrategiesRes.data);
        setStrategies(strategiesRes.data);
        setNews(newsRes.data);
        setLoadingScreen(false);
      } catch (error) {
        dispatch(show({ type: "error", msg: t("errors:serverError") }));
      }
    };

    apiCalls();
  }, [dispatch, t]);

  const handleSortChange = (event, target) => {
    setSortType(target);
  };

  const handleSharedLoad = (id, url) => async () => {
    try {
      await load("S", id);
      setLoadDestination(url);
      setLoaded(true);
    } catch (error) {
      dispatch(show({ type: "error", msg: t("errors:loadStrategy") }));
    }
  };

  const handleProfitableLoad = async () => {
    try {
      await load("M", summary.stratid);
      setLoadDestination("/builder");
      setLoaded(true);
    } catch (error) {
      dispatch(show({ type: "error", msg: t("errors:loadStrategy") }));
    }
  };

  const handleCreateNew = () => {
    dispatch(resetBuilder());
    dispatch(reset());
    setLoadDestination("/builder");
    setLoaded(true);
  };

  if (loadingScreen) {
    return <LoadingShow />;
  }

  if (loaded) {
    return <Redirect to={loadDestination} push />;
  }

  return (
    <Box
      display="flex"
      height="100%"
      width="100%"
      flexDirection="column"
      overflow="auto"
      sx={{
        padding: {
          sm: "0 8px",
          lg: "0 0 0 8px",
        },
      }}
    >
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        sx={{
          margin: {
            lg: "0 0 8px 0",
          },
          height: {
            lg: "60%",
          },
        }}
        className={classes.dashboardRow}
      >
        <Box
          borderRadius="10px"
          display="flex"
          width="100%"
          flexDirection="column"
          className={classes.leftContainer}
        >
          <Box
            bgcolor="#253E66"
            borderRadius="inherit"
            mb={1}
            className={classes.leftSummaryContainer}
            width="100%"
            display="flex"
            flexDirection="column"
          >
            <Typography
              color="primary"
              variant="h6"
              gutterBottom
              style={{ padding: "16px 16px 0 16px" }}
            >
              {t("dashboard:summary")}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              justifyContent="space-around"
              height="100%"
            >
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                pl={2}
                mb={1}
              >
                <Typography color="secondary" variant="h6">
                  <Typography
                    component="span"
                    variant="h5"
                    fontWeight={500}
                    fontFamily="Poppins"
                  >
                    {summary.built}
                  </Typography>{" "}
                  {t("dashboard:built")}
                </Typography>
                <Box
                  height="100%"
                  py={0.5}
                  ml="auto"
                  pl={0.5}
                  pr={2.5}
                  bgcolor="#F7F7FA"
                  borderRadius="10px 0 0 10px"
                >
                  <Button
                    endIcon={<AddCircleOutlineIcon />}
                    onClick={handleCreateNew}
                  >
                    {t("dashboard:new")}
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                pl={2}
                py={1}
                mb={1}
              >
                <Typography color="secondary" variant="h6">
                  <Typography
                    component="span"
                    variant="h5"
                    fontWeight={500}
                    fontFamily="Poppins"
                  >
                    {summary.validated}
                  </Typography>{" "}
                  {t("dashboard:validated")}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                pl={2}
                py={1}
                mb={1}
              >
                <Typography color="secondary" variant="h6">
                  <Typography
                    component="span"
                    variant="h5"
                    fontWeight={500}
                    fontFamily="Poppins"
                  >
                    {summary.downloaded}
                  </Typography>{" "}
                  {t("dashboard:downloaded")}
                </Typography>
              </Box>
              <Box px={2} width="100%" py={1}>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  {t("dashboard:mostprofit")}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography color="secondary" variant="h6" gutterBottom>
                    <Typography
                      component="span"
                      variant="h5"
                      style={{
                        fontFamily: "poppins",
                        color: "#11CC9A",
                        fontWeight: 500,
                      }}
                    >
                      +
                      {summary.mostProfit.toLocaleString(undefined, {
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1,
                      })}
                      %
                    </Typography>{" "}
                    {t("dashboard:morepofit")}
                  </Typography>
                  <LoadingButton
                    loading={working}
                    loadingPosition="end"
                    variant="outlined"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    disabled={!summary.stratid}
                    onClick={handleProfitableLoad}
                  >
                    {t("dashboard:loadStrategy")}
                  </LoadingButton>
                </Box>
              </Box>
              <Box px={2} width="100%" py={1}>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  {t("dashboard:profitable")}
                </Typography>
                <Box display="flex" alignItems="center" pb={2}>
                  <Typography color="secondary" variant="h5">
                    <Typography
                      component="span"
                      variant="inherit"
                      fontWeight={500}
                      fontFamily="Poppins"
                    >
                      {summary.profitable}
                    </Typography>
                    /{summary.built}
                    <Typography component="sup">
                      ({percentage(summary.profitable, summary.built)}%)
                    </Typography>
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={parseFloat(
                      percentage(summary.profitable, summary.built)
                    )}
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      width: "90%",
                      marginLeft: 12,
                      backgroundColor: "#27767b",
                      "& .MuiLinearProgress-bar": {
                        borderRadius: 5,
                        backgroundColor: "#11CC9A",
                      },
                    }}
                    className={classes.profitableBar}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#FFF",
              borderRadius: {
                xs: "inherit",
                lg: "0 10px 10px 0",
              },
              height: {
                lg: "100%",
              },
              padding: 2,
              margin: {
                xs: "0 0 8px 0",
                lg: 0,
              },
              width: {
                xs: "100%",
                lg: "50%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              className={classes.chartContainer}
            >
              <Typography variant="h6" color="textSecondary">
                {t("dashboard:topassets")}
              </Typography>
              {summary.assetsUsed.length > 0 && (
                <Box
                  width="100%"
                  display="flex"
                  height="100%"
                  flexDirection="column"
                  className={classes.dataContainer}
                >
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.pieContainer}
                  >
                    <CustomDonutChart
                      series={summary.assetsUsed.map((asset) => asset.count)}
                      labels={summary.assetsUsed.map((asset) => asset.asset)}
                      colors={assetColors}
                    />
                  </Box>
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.legendContainer}
                  >
                    {summary.assetsUsed.map((asset, index) => (
                      <Box
                        width="100%"
                        display="flex"
                        key={index}
                        mb={1}
                        alignItems="center"
                        justifyContent="space-between"
                        whiteSpace="nowrap"
                      >
                        <Box display="flex" width="50%">
                          <Box
                            py={1}
                            px={0.5}
                            bgcolor={assetColors[index]}
                            component="span"
                            mr={1}
                          />
                          <Typography>{asset.asset}</Typography>
                        </Box>
                        <Box
                          width="50%"
                          display="flex"
                          height="100%"
                          justifyContent="flex-end"
                        >
                          <Typography color="textSecondary" noWrap>
                            <Typography component="span">
                              {asset.count} {t("dashboard:title")}{" "}
                            </Typography>
                            ({percentage(asset.count, summary.built)}
                            %)
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.chartContainer}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" color="textSecondary">
                  {t("dashboard:sharedStrategies")}
                </Typography>
                <Button
                  component={Link}
                  to="/strategies"
                  endIcon={<ArrowForwardIcon />}
                >
                  {t("dashboard:shareButton")}
                </Button>
              </Box>
              <Box
                width="100%"
                display="flex"
                height="100%"
                flexDirection="column"
                className={classes.dataContainer}
              >
                <Box
                  display="flex"
                  width="100%"
                  height="100%"
                  className={classes.pieContainer}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CustomDonutChart
                    series={[summary.shared, summary.built]}
                    labels={[t("dashboard:shared"), t("dashboard:notShared")]}
                    colors={sharedColors}
                  />
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className={classes.legendContainer}
                >
                  <Box
                    width="100%"
                    display="flex"
                    mb={1}
                    justifyContent="space-between"
                    whiteSpace="noWrap"
                  >
                    <Box display="flex">
                      <Box
                        py={1}
                        px={0.5}
                        bgcolor={sharedColors[0]}
                        component="span"
                        mr={1}
                      />
                      <Typography>{t("dashboard:shared")}</Typography>
                    </Box>
                    <Typography color="textSecondary">
                      {summary.shared} {t("dashboard:title")} (
                      {percentage(summary.shared, summary.built)}
                      %)
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    mb={1}
                    whiteSpace="noWrap"
                    justifyContent="space-between"
                  >
                    <Box display="flex">
                      <Box
                        py={1}
                        px={0.5}
                        bgcolor={sharedColors[1]}
                        component="span"
                        mr={1}
                      />
                      <Typography>{t("dashboard:notShared")}</Typography>
                    </Box>
                    <Typography color="textSecondary" noWrap>
                      {summary.built - summary.shared} {t("dashboard:title")} (
                      {percentage(
                        summary.built - summary.shared,
                        summary.built
                      )}
                      %)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          bgcolor="#FFF"
          p={2}
          mb={1}
          sx={{
            width: {
              xs: "100%",
              lg: "30%",
            },
            borderRadius: {
              xs: "10px",
              lg: "10px 0 0 10px",
            },
          }}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            width="100%"
          >
            <Typography
              variant="h6"
              color="textSecondary"
              fontWeight={500}
              fontFamily="Catamaran"
              mr={1}
            >
              {t("dashboard:sharedStrategies")}
            </Typography>
            <Tabs
              value={sortType}
              onChange={handleSortChange}
              sx={{
                color: "#555559",
                minHeight: 0,
                backgroundColor: "#F7F7FA",
                borderRadius: "5px",
                padding: "3px",
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .Mui-selected": {
                  backgroundColor: "#25AAE2",
                  color: "#FFFFFF !important",
                },
              }}
            >
              <Tab
                value="popular"
                label={t("dashboard:popular")}
                sx={{ minHeight: 0, minWidth: 0, p: 0.5 }}
              />
              <Tab
                value="recent"
                label={t("dashboard:recent")}
                sx={{ minHeight: 0, minWidth: 0, p: 0.5 }}
              />
            </Tabs>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            width="100%"
            height="100%"
            overflow="hidden"
          >
            {[...sharedStrategies]
              .sort((a, b) => {
                if (sortType === "popular") {
                  return b.downloads - a.downloads;
                }
                return new Date(b.shared_date) - new Date(a.shared_date);
              })
              .slice(0, 3)
              .map((strategy) => (
                <Box
                  borderRadius="5px"
                  key={strategy.id}
                  bgcolor="#F7F7FA"
                  display="flex"
                  flexDirection="column"
                  position="relative"
                  width="100%"
                  mb={0.5}
                >
                  <Box
                    position="absolute"
                    height="100%"
                    display="flex"
                    sx={{
                      opacity: 0,
                      transition: (theme) =>
                        theme.transitions.create("opacity"),
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                    borderRadius="5px"
                    flexDirection="column"
                    width="100%"
                    border={1}
                    borderColor="#EBEBF2"
                    px="30%"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(235, 235, 242, 0.8)"
                    zIndex="2"
                  >
                    {isUserFree ? (
                      <ProtectedComponent
                        iconTop="-30px"
                        iconRight="2px"
                        iconSize="20"
                        iconColor="grey"
                      >
                        <Button fullWidth endIcon={<ArrowForwardIcon />}>
                          {t("dashboard:builder")}
                        </Button>
                      </ProtectedComponent>
                    ) : (
                      <LoadingButton
                        fullWidth
                        loading={working}
                        variant="contained"
                        loadingPosition="end"
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleSharedLoad(strategy.id, "/builder")}
                      >
                        {t("dashboard:builder")}
                      </LoadingButton>
                    )}

                    <Box my={0.5} />
                    {isUserFree ? (
                      <ProtectedComponent
                        iconTop="-30px"
                        iconRight="2px"
                        iconSize="20"
                        iconColor="grey"
                      >
                        <Button fullWidth endIcon={<TimelineIcon />}>
                          {t("dashboard:validate")}
                        </Button>
                      </ProtectedComponent>
                    ) : (
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        loading={working}
                        loadingPosition="end"
                        endIcon={<TimelineIcon />}
                        onClick={handleSharedLoad(strategy.id, "/validation")}
                      >
                        {t("dashboard:validate")}
                      </LoadingButton>
                    )}
                  </Box>
                  <Box
                    px={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    mb={0.2}
                    pt={1}
                  >
                    <Typography
                      fontFamily="Catamaran"
                      width="50%"
                      noWrap
                      variant="body2"
                    >
                      {strategy.title}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      ({strategy.downloads} {t("dashboard:downloads")})
                    </Typography>
                  </Box>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ padding: "0 8px" }}
                  >
                    {strategy.user.name}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    px={1}
                    mt={1}
                  >
                    <ProfitRiskBox
                      profit={strategy.profit}
                      risk={strategy.risk}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      mb={1}
                    >
                      <Typography
                        color="textSecondary"
                        variant="caption"
                        gutterBottom
                      >
                        Timeframe:{" "}
                        <Typography
                          component="span"
                          variant="inherit"
                          color={strategy.tf ? "textPrimary" : "error"}
                        >
                          {strategy.tf
                            ? t(`timeframes:${strategy.tf.name}`)
                            : "undefined"}
                        </Typography>
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="caption"
                        gutterBottom
                      >
                        {t("dashboard:asset")}:{" "}
                        <Typography
                          component="span"
                          variant="inherit"
                          noWrap
                          color={strategy.ticker ? "textPrimary" : "error"}
                        >
                          {strategy.ticker
                            ? strategy.ticker.display_name
                            : "undefined"}
                        </Typography>
                      </Typography>
                      <Typography color="textSecondary" variant="caption">
                        {t("dashboard:created")}:{" "}
                        <Typography
                          component="span"
                          color="textPrimary"
                          variant="inherit"
                        >
                          {format(new Date(strategy.sharedDate), "dd.MM.yyyy")}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        className={clsx(classes.dashboardRow, classes.bottomRow)}
      >
        <Box
          borderRadius="10px"
          bgcolor="#FFF"
          width="100%"
          display="flex"
          flexDirection="column"
          className={classes.leftContainer}
          mb={1}
        >
          <Box
            p={2}
            bgcolor="#EBEBF2"
            width="100%"
            height="100%"
            borderRadius="10px 10px 0 0"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            className={classes.lastStrategyContainer}
          >
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {t("dashboard:lastStrategy")}
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              // px={4}
              height="100%"
            >
              <StrategyCard isSession />
            </Box>
          </Box>
          <Box
            p={2}
            width="100%"
            className={classes.myStrategiesContainer}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="h6" color="textPrimary">
                {t("dashboard:myStrategies")}
              </Typography>
              <Button
                component={Link}
                to="/strategies"
                endIcon={<ArrowForwardIcon />}
              >
                {t("dashboard:allStrategies")}
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.strategyContainer}
              width="100%"
              overflow="hidden"
              justifyContent="space-between"
              height="100%"
            >
              {strategies.slice(0, 3).map((strategy) => (
                <StrategyCard
                  key={strategy.id}
                  id={strategy.id}
                  title={strategy.title}
                  creationDate={strategy.created_at}
                  timeframe={strategy.tf && t(`timeframes:${strategy.tf.name}`)}
                  asset={strategy.ticker && strategy.ticker.display_name}
                  image={strategy.image}
                  profit={strategy.profit}
                  risk={strategy.risk}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          borderRadius="10px"
          bgcolor="#FFF"
          p={2}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          sx={{
            width: {
              xs: "100%",
              lg: "30%",
            },
            borderRadius: {
              xs: "10px",
              lg: "10px 0 0 0",
            },
          }}
        >
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {t("dashboard:news")}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            overflow="auto"
          >
            {news
              .filter(
                (noticia) => noticia.lang_id === i18n.language.toUpperCase()
              )
              .map((_new) => (
                <Box
                  width="100%"
                  bgcolor="#405980"
                  p={2}
                  borderRadius="5px"
                  mb={1}
                  key={_new.id}
                  color="#FFF"
                >
                  <Typography
                    color="inherit"
                    component="a"
                    variant="body1"
                    href={_new.link}
                    target="_blank"
                  >
                    {_new.title}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography color="textSecondary" variant="caption">
                      {format(new Date(_new.creation_date), "PPPppp")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {_new.author}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
