import type Element from "./Element";

export type RuleConstructor = {
  id: string;
  number: number;
  type?: string;
  readingType?: string;
  elements?: Array<Element>;
  triggerCount?: number;
  active?: boolean;
  hasErrors?: boolean;
};

export default class Rule {
  readonly id: string;

  number: number;

  type: string;

  readingType: string;

  elements: Array<Element>;

  triggerCount: number;

  active: boolean;

  hasErrors: boolean;

  constructor({
    id,
    number,
    type = "error",
    readingType = "1",
    elements = [],
    triggerCount = 0,
    active = false,
    hasErrors = true,
  }: RuleConstructor) {
    this.id = id;
    this.number = number;
    this.type = type;
    this.readingType = readingType;
    this.elements = elements;
    this.triggerCount = triggerCount;
    this.active = active;
    this.hasErrors = hasErrors;
  }
}
