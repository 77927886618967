import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export default responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: [
        "Karla",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontWeight: 400,
        lineHeight: 1,
      },
      h2: {
        fontWeight: 400,
        lineHeight: 1,
      },
      h3: { fontWeight: 400, lineHeight: 1 },
      h4: { fontWeight: 400, lineHeight: 1 },
      h5: { fontWeight: 400, lineHeight: 1 },
      h6: {
        fontWeight: 400,
        lineHeight: 1,
      },
      caption: {
        lineHeight: 1,
      },
      body1: {
        lineHeight: 1,
      },
    },
    palette: {
      primary: {
        main: "#27AAE1",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#FFFFFF",
        contrastText: "#25AAE2",
      },
      text: {
        primary: "#555559",
        secondary: "#9D9DA6",
      },
      success: {
        main: "#11CC9A",
      },
      warning: {
        main: "#E5AA17",
      },
      error: {
        main: "#E3170A",
      },
      info: {
        main: "#25AAE2",
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            padding: "0 16px",
            borderRadius: "5px 0 0 5px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#898989",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 0,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#25AAE2",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: "#898989",
            borderRadius: 5,
          },
          notchedOutline: { borderColor: "#EBEBF2" },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderRadius: 5,
            textTransform: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 5,
            textTransform: "none",
          },
        },
        defaultProps: {
          disableElevation: true,
          variant: "contained",
          color: "primary",
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: "1px solid #E3E3E6",
            borderLeft: 0,
            borderRight: 0,
            boxShadow: "none",
            "&:not(:last-child)": {
              borderBottom: 0,
            },
            "&:before": {
              display: "none",
            },
          },
        },
        defaultProps: {
          square: true,
          elevation: 0,
          variant: "outlined",
          disableGutters: true,
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: "#F7F7FA",
            borderRadius: 0,
          },
          content: {
            alignItems: "center",
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            borderTop: "1px solid #E3E3E6",
          },
        },
      },
      MuiDialog: {
        defaultProps: { PaperProps: { elevation: 0 } },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          rounded: {
            borderRadius: "10px",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiList: {
        defaultProps: { disablePadding: true },
      },
      MuiMenu: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          paper: { border: "1px solid #D7D7D9", borderRadius: "5px" },
          list: {
            "& li:nth-last-of-type(1)": {
              border: 0,
            },
          },
        },
      },
      MuiMenuItem: {
        defaultProps: { dense: true },
        styleOverrides: {
          root: {
            borderRadius: 0,
            borderBottom: "1px solid",
            borderColor: "#D7D7D9",
            color: "#27AAE1",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: "5px",
            backgroundColor: "#253E66",
          },
          arrow: {
            color: "#253E66",
          },
        },
      },
    },
  })
);
