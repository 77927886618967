import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";
// Assets
import rotate from "assets/img/rotate.png";

type RotateViewProps = {
  title: string;
};

const RotateView = function ({ title = "" }: RotateViewProps) {
  const [t] = useTranslation("global");

  return (
    <Box width="100%" height="100%">
      <Typography
        variant="h3"
        noWrap
        sx={{
          position: "absolute",
          top: 70,
          left: 70,
          zIndex: 10,
          display: { sm: "none" },
        }}
        display="block"
      >
        {t(title)}
      </Typography>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src={rotate} width="150" alt="Rotate your device" />
        <Typography variant="h4">Rotate to view full chart</Typography>
      </Box>
    </Box>
  );
};

export default RotateView;
