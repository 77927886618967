export interface ParameterConstructor {
  id: number;
  name: string; // Aka reference in API
  displayName: string;
  type: string;
  defaultValue: string;
  defaultOpen?: string | number;
  defaultClose?: string | number;
  value: string;
  values: string | null;
  parent: number | null;
  parentValue: string | null;
  onlyExit: boolean;
  visible: boolean;
  boxVisible: boolean;
}

export default class Parameter {
  id: number;

  name: string; // Aka reference in API

  displayName: string;

  type: string;

  defaultValue: string;

  defaultOpen?: string | number;

  defaultClose?: string | number;

  value: string;

  values: string | null;

  parent: number | null;

  parentValue: string | null;

  onlyExit: boolean;

  visible: boolean;

  boxVisible: boolean;

  constructor({
    id,
    name,
    displayName = name,
    type,
    defaultValue,
    defaultOpen,
    defaultClose,
    value,
    values,
    parent,
    parentValue,
    onlyExit,
    visible,
    boxVisible,
  }: ParameterConstructor) {
    this.id = id;
    this.name = name;
    this.displayName = displayName;
    this.type = type;
    this.defaultValue = defaultValue;
    this.defaultOpen = defaultOpen;
    this.defaultClose = defaultClose;
    this.value = value;
    this.values = values;
    this.parent = parent;
    this.parentValue = parentValue;
    this.onlyExit = onlyExit;
    this.visible = visible;
    this.boxVisible = boxVisible;
  }
}
