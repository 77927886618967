import {
    Route,
    Redirect
  } from 'react-router-dom';
import PropTypes from 'prop-types';
  
  function PrivateRoute({ children, isAuthenticated, ...rest }) {
    return (
      <Route
        {...rest}
        render={
          ({ location }) => (
            isAuthenticated
              ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: location }
                  }}
                />
              ))
        }
      />
    );
  }
  
  PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.func,
    children: PropTypes.element,
  }
  
  export default PrivateRoute;