import { Box, CircularProgress } from "@mui/material";

const LoadingShow = function () {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      overflow="auto"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingShow;
