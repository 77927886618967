import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { formatNumberWithLocale } from "lib/helpers";
import type { Variant } from "@mui/material/styles/createTypography";

type IProps = {
  profit: number;
  risk: number;
  profitLabelColor?: string;
  riskLabelColor?: string;
  profitColor?: string;
  riskColor?: string;
  profitBgcolor?: string;
  riskBgcolor?: string;
  profitBorderRadius?: string;
  riskBorderRadius?: string;
  variantTitle?: Variant;
  variant?: Variant;
  px?: number;
  py?: number;
};

/**
 * Box to display profit & risk
 * @component
 * @example
 * <ProfitRiskBox profit={50.54} risk={10} />
 */
const ProfitRiskBox = function ({
  profit,
  risk,
  profitLabelColor = "secondary",
  riskLabelColor = "secondary",
  profitColor = "#11CC9A",
  riskColor = "#253E66",
  profitBgcolor = "#253E66",
  riskBgcolor = "#E5AA17",
  profitBorderRadius = "10px 0 0 10px",
  riskBorderRadius = "0 10px 0 0",
  variantTitle = "caption",
  variant = "body1",
  px = 1,
  py = 0.3,
}: IProps) {
  const { i18n } = useTranslation();
  return (
    <Box display="flex">
      <Box display="flex" alignItems="center">
        <Box
          bgcolor={profitBgcolor}
          color={profitColor}
          display="flex"
          flexDirection="column"
          borderRadius={profitBorderRadius}
          px={px}
          mr="1px"
          py={py}
        >
          <Typography
            color={profitLabelColor}
            variant={variantTitle}
            fontWeight={700}
          >
            Profit
          </Typography>
          <Typography fontWeight={500} fontFamily="Poppins" variant={variant}>
            {profit > 0 && "+"}
            {formatNumberWithLocale(profit, 1, i18n.language, "decimal")}%
          </Typography>
        </Box>
        <Box
          bgcolor={riskBgcolor}
          color={riskColor}
          display="flex"
          flexDirection="column"
          borderRadius={riskBorderRadius}
          px={px}
          py={py}
        >
          <Typography color={riskLabelColor} variant={variantTitle}>
            Risk
          </Typography>
          <Typography fontWeight={500} fontFamily="Poppins" variant={variant}>
            {risk.toLocaleString(undefined, { maximumFractionDigits: 0 })}%
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfitRiskBox;
