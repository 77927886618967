import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Frameworks
import {
  Box,
  Modal,
  Fade,
} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import customAxios from "lib/axios";
import useAppDispatch from "hooks/useAppDispatch";
import { show } from "store/reducers/notifications.reducer";

// Assets
import Waiting from "assets/img/waiting.png";

const LingkingProgress = function () {
  const history = useHistory();

  const [t] = useTranslation(["tradingRobots"]);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const apiCalls = async () => {
      const userId = localStorage.getItem("user_id");
      try {
        const responds = await customAxios(`/tradingrobots/isLinkedAccount/${userId}`);
        console.log(responds);
        if (responds.data[0].ACTIVE != "N") {
          history.push("/tradingrobots/main");
        } else {
          // alert("Trying...")
          setTimeout(apiCalls, 5000);
        }
      } catch (error) {
        dispatch(show({ type: "error", msg: t("errors:serverError") }));
      }
    };
    apiCalls();
  }, []);

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={4}
          py={1}
          width={300}
          height={470}
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color="white"
          textAlign="center"
          lineHeight="1.2"
          position="relative"
        >
         <HighlightOffIcon sx={{position: "absolute", top: '10px', right: "10px", cursor:"pointer"}} onClick={()=>{history.push("/")}} />
         <h1>{t("linkingProgress.beingLinked")}</h1>
         <Box sx={{position: "relative"}}
              my={2}
              display="flex"
              justifyContent="center"
              alignItems="center">
         <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
              animationDuration: '2000ms',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
            size={155}
            thickness={2}
          />
          <img src={Waiting} style={{position: "absolute"}} alt="Waiting" width="140px" height="140px"/>
          </Box>
         <h2>{t("linkingProgress.receiveEmail")}</h2>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LingkingProgress;
