import Rule from "./Rule";
import type { RuleConstructor } from "./Rule";

export interface CloseRuleConstructor extends RuleConstructor {
  closeBuys: string;
  closeSells: string;
  closingVolume?: number; // TODO: Move to string
}

export default class CloseRule extends Rule {
  closeBuys: string;

  closeSells: string;

  closingType: string;

  closingVolume: number; // TODO: Move to string

  disabledElements: number[]; // TODO: Move to Rule class

  constructor({
    id,
    number,
    hasErrors = true,
    closeBuys = "1",
    closeSells = "1",
    closingVolume = 100,
    readingType = "1",
    elements = [],
    triggerCount = 0,
    active = true,
  }: CloseRuleConstructor) {
    super({
      id,
      number,
      type: "close",
      readingType,
      elements,
      triggerCount,
      hasErrors,
      active,
    });
    this.hasErrors = hasErrors;
    this.closeBuys = closeBuys;
    this.closeSells = closeSells;
    this.closingType = "0";
    this.closingVolume = closingVolume;
    this.disabledElements = [];
  }
}
