import Rule from "./Rule";
import type { RuleConstructor } from "./Rule";

export interface OpenRuleConstructor extends RuleConstructor {
  openBuys: string;
  openSells: string;
  allowSameType?: string;
  allowDifferentType?: string;
  openVolType?: string;
  porcentage?: string;
  volume?: string;
}

export default class OpenRule extends Rule {
  openBuys: string;

  openSells: string;

  allowSameType: string;

  allowDifferentType: string;

  openVolType: string;

  porcentage: string;

  volume: string;

  disabledElements: number[]; // TODO: Move to Rule class

  constructor({
    id,
    number,
    hasErrors = true,
    openBuys = "1",
    openSells = "1",
    allowSameType = "0",
    allowDifferentType = "1",
    openVolType = "0",
    porcentage = "1",
    volume = "1",
    readingType = "1",
    triggerCount = 0,
    elements = [],
    active = true,
  }: OpenRuleConstructor) {
    super({
      id,
      number,
      type: "open",
      readingType,
      elements,
      triggerCount,
      hasErrors,
      active,
    });
    this.hasErrors = hasErrors;
    this.openBuys = openBuys;
    this.openSells = openSells;
    this.allowSameType = allowSameType;
    this.allowDifferentType = allowDifferentType;
    this.openVolType = openVolType;
    this.porcentage = porcentage;
    this.volume = volume;
    this.disabledElements = [69, 70];
  }
}
