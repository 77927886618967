import { configureStore } from "@reduxjs/toolkit";
// Reducers
import { tradeasyApi } from "lib/api";
import strategyReducer from "./reducers/strategy.reducer";
import builderReducer from "./reducers/builder.reducer";
import robotReducer from "./reducers/robot.reducer";
import notificationReducer from "./reducers/notifications.reducer";

const store = configureStore({
  reducer: {
    [tradeasyApi.reducerPath]: tradeasyApi.reducer,
    strategy: strategyReducer,
    robot: robotReducer,
    notifications: notificationReducer,
    builder: builderReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable non-serializable value in state error
    }).concat(tradeasyApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
