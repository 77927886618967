import { useEffect, useState } from "react";
// Frameworks
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Menu,
  Skeleton,
  Box,
  Avatar,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import { show } from "store/reducers/notifications.reducer";
// types
// Assets
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const UserZone = function () {
  const [username, setUsername] = useState("");
  const [avatarimg, setavatarimg] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [t] = useTranslation(["dashboard"]);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  useEffect(() => {
    setLoading(true);
    const ABORT_CONTROLLER = new AbortController();
    async function apiCalls() {
      const URL = process.env.REACT_APP_TRADEASY_API;
      const USER_ID = localStorage.getItem("user_id");
      try {
        const response = await fetch(`${URL}users/${USER_ID}`, {
          signal: ABORT_CONTROLLER.signal,
        });
        if (!response.ok)
          throw new Error("Profile name fetch finished with errors.");
        const data = await response.json();
        setUsername(data.alias);
        setavatarimg(data.avatar_img);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(
          show({
            type: "error",
            msg: "Error del servidor, contacte con el soporte.",
          })
        );
      }
    }
    apiCalls();
    return () => {
      ABORT_CONTROLLER.abort();
    };
  }, [dispatch]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    localStorage.removeItem("user_id");
    window.location.reload();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor="white"
      borderRadius="5px"
      mx={1}
    >
      <Avatar
        variant="square"
        style={{ width: "40px", height: "40px", borderRadius: 5 }}
        src={avatarimg}
        alt={username}
      >
        {username[0]}
      </Avatar>
      <Box mx={1} display="flex" alignItems="center">
        <Typography
          variant="body1"
          noWrap
          color="textSecondary"
          display="none"
          sx={{
            padding: { md: "0 6px" },
            display: { md: "block" },
          }}
        >
          {isLoading ? <Skeleton width={50} /> : username}
        </Typography>

        <IconButton color="primary" onClick={handleOpen} size="small">
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem component={Link} to="/profile" onClick={handleClose}>
          {t("profile")}
        </MenuItem>
        <MenuItem onClick={onLogout}>{t("logout")}</MenuItem>
      </Menu>
    </Box>
  );
};

export default UserZone;
