import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// Translations
import globalEs from "translations/es/global.json";
import globalEn from "translations/en/global.json";
import elementsEs from "translations/es/elements.json";
import elementsEn from "translations/en/elements.json";
import parametersEs from "translations/es/parameters.json";
import parametersEn from "translations/en/parameters.json";
import dashboardEn from "translations/en/dashboard.json";
import dashboardEs from "translations/es/dashboard.json";
import builderEn from "translations/en/builder.json";
import builderEs from "translations/es/builder.json";
import strategiesEn from "translations/en/strategies.json";
import strategiesEs from "translations/es/strategies.json";
import validationEn from "translations/en/validation.json";
import validationEs from "translations/es/validation.json";
import myValidationEn from "translations/en/myvalidation.json";
import myValidationEs from "translations/es/myvalidation.json";
import tradingViewEn from "translations/en/tradingview.json";
import tradingViewEs from "translations/es/tradingview.json";
import errorsEn from "translations/en/errors.json";
import errorsEs from "translations/es/errors.json";
import freemiumEn from "translations/en/freemium.json";
import freemiumEs from "translations/es/freemium.json";
import TimeframeEn from "translations/en/timeframes.json";
import TimeframesEs from "translations/es/timeframes.json";
import LoginEs from "translations/es/login.json";
import LoginEn from "translations/en/login.json";
import profileEs from "translations/es/profile.json";
import profileEn from "translations/en/profile.json";
import downloadEs from "translations/es/download.json";
import downloadEn from "translations/en/download.json";
import robotsEs from "translations/es/robots.json";
import robotsEn from "translations/en/robots.json";
import TradingRobotsEn from "translations/en/tradingrobots.json";
import TradingRobotsEs from "translations/es/tradingrobots.json";

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    supportedLngs: ["es", "en"],
    fallbackLng: "en",
    resources: {
      es: {
        errors: errorsEs,
        global: globalEs,
        elements: elementsEs,
        parameters: parametersEs,
        dashboard: dashboardEs,
        builder: builderEs,
        strategies: strategiesEs,
        validation: validationEs,
        myValidation: myValidationEs,
        tradingView: tradingViewEs,
        login: LoginEs,
        profile: profileEs,
        freemium: freemiumEs,
        timeframes: TimeframesEs,
        download: downloadEs,
        robots: robotsEs,
        tradingRobots: TradingRobotsEs,
      },
      en: {
        errors: errorsEn,
        global: globalEn,
        elements: elementsEn,
        parameters: parametersEn,
        dashboard: dashboardEn,
        builder: builderEn,
        strategies: strategiesEn,
        validation: validationEn,
        myValidation: myValidationEn,
        tradingView: tradingViewEn,
        login: LoginEn,
        profile: profileEn,
        freemium: freemiumEn,
        timeframes: TimeframeEn,
        download: downloadEn,
        robots: robotsEn,
        tradingRobots: TradingRobotsEn,
      },
    },
  });
