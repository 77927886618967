import { Component, ErrorInfo, ReactNode } from "react";
import { Typography } from "@mui/material";

type Props = {
  children: ReactNode;
};
type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(_error: Error, _errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error("Error capturado******", _error, _errorInfo);
  }

  // setError() {
  //   this.setState({ hasError: false })
  // }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Typography>
          There was an unexpected error in tradEasy, please contact support
        </Typography>
      );
    }

    return children;
  }
}
