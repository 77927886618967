import { createSlice } from "@reduxjs/toolkit";
import type { AlertColor } from "@mui/material";

type ShowReducerActionPayload = {
  type: AlertColor;
  msg: string;
};

type ShowReducerAction = {
  payload: ShowReducerActionPayload;
  type: string;
};

type State = {
  show: boolean;
  type: AlertColor;
  message: string;
};

const initialState: State = {
  show: false,
  type: "info",
  message: "",
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    reset: () => initialState,
    hide: (state) => {
      state.show = false;
    },
    show: (state, action: ShowReducerAction) => {
      const { type, msg } = action.payload;
      state.show = true;
      state.type = type;
      state.message = msg;
    },
  },
});

export const { reset, hide, show } = notificationsSlice.actions;
export default notificationsSlice.reducer;
