import axios from "axios";
import axiosRetry from "axios-retry";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_TRADEASY_API,
});

axiosRetry(customAxios, { retries: 3, retryDelay: () => 5000 });

export default customAxios;
