import { SvgIcon } from "@mui/material";

const BinanceIcon = function () {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      height="800"
      width="1200"
      viewBox="-52.785 -88 457.47 528"
    >
      <path d="M79.5 176l-39.7 39.7L0 176l39.7-39.7zM176 79.5l68.1 68.1 39.7-39.7L176 0 68.1 107.9l39.7 39.7zm136.2 56.8L272.5 176l39.7 39.7 39.7-39.7zM176 272.5l-68.1-68.1-39.7 39.7L176 352l107.8-107.9-39.7-39.7zm0-56.8l39.7-39.7-39.7-39.7-39.8 39.7z" />
    </SvgIcon>
  );
};

export default BinanceIcon;
