import SvgIcon from "@mui/material/SvgIcon";

const StrategiesIcon = function () {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <path d="M2.70312 10.7344H13.2969V3H2.70312V10.7344ZM8.35156 4.75781H9.05469V4.05469H9.75781V4.75781H10.4609V8.97656H9.75781V9.67969H9.05469V8.97656H8.35156V4.75781ZM5.53906 6.16406H6.24219V5.46094H6.94531V6.16406H7.64844V8.97656H6.94531V9.67969H6.24219V8.97656H5.53906V6.16406ZM9.75781 5.46094H9.05469V8.27344H9.75781V5.46094ZM3.05469 12.8438C2.4722 12.8438 2 12.3716 2 11.7891V11.4375H14V11.7891C14 12.3716 13.5278 12.8438 12.9453 12.8438H3.05469ZM6.94531 6.86719H6.24219V8.27344H6.94531V6.86719Z" />
    </SvgIcon>
  );
};

export default StrategiesIcon;
