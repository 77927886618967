// Frameworks
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// Custom imports
import "lib/i18n"; // Translation configuration.
import App from "views/_app";
import ErrorBoundary from "components/common/ErrorBoundary";
import AutoLogOut from "lib/AutoLogOut";
import ValidateHash from "lib/ValidateHash";
import theme from "lib/theme";
import store from "store/index.store";
import { StrictMode } from "react";

ReactDOM.render(
  <StrictMode>
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ErrorBoundary>
            <Provider store={store}>
              <CssBaseline />
              <AutoLogOut idleMinutes={120} />
              <ValidateHash idleMinutes={5} />
              <Route path="/:page?" render={() => <App />} />
            </Provider>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </DndProvider>
  </StrictMode>,

  document.querySelector("#root")
);
