import { createSlice } from "@reduxjs/toolkit";
import { startOfYesterday, startOfDay } from "date-fns";
import type { RobotState } from "types/builder.reducer";

const initialState: RobotState = {
  id: null,
  title: "",
  image: "",
  startDate: startOfDay(new Date(2021, 1, 1)),
  endDate: startOfYesterday(),
  balance: 1000,
  currency: "",
  gain: 0,
  tradesWon: 0,
  tradesLost: 0,
  successRate: 0,
  gainsOnly: 0,
  losesOnly: 0,
  gainLossRate: 0,
  bestGain: 0,
  worstLoss: 0,
  drawdown: 0,
  relativeDrawdown: 0,
  runup: 0,
  relativeRunup: 0,
  isSaved: false,
  isValidated: false,
  isShared: false,
};

export const robotSlice = createSlice({
  name: "robot",
  initialState,
  reducers: {
    reset: () => initialState,
    loadRobot: (state, action) => {
      const {
        id,
        title,
        balance,
        startDate,
        isShared,
        isSaved = true,
        isValidated = false,
        validation,
      } = action.payload;
      if (id) state.id = id;
      if (title) state.title = title;
      if (balance) state.balance = balance;
      state.endDate = startOfYesterday();
      if (startDate) state.startDate = new Date(startDate);
      state.isValidated = isValidated;
      state.isSaved = isSaved;
      state.isShared = isShared;
      if (isValidated) {
        state.gain = validation.gain;
        state.tradesWon = validation.tradesWon;
        state.tradesLost = validation.tradesLost;
        state.successRate = validation.successRate;
        state.gainsOnly = validation.gainsOnly;
        state.losesOnly = validation.losses;
        state.gainLossRate = validation.gainLossRate;
        state.bestGain = validation.bestGain;
        state.worstLoss = validation.worstLoss;
        state.drawdown = validation.drawdown;
        state.relativeDrawdown = validation.relativeDrawdown;
        state.runup = validation.runup;
        state.relativeRunup = validation.relativeRunup;
        state.image = validation.image;
        state.currency = validation.currency;
      }
    },
    changeTitle: (state, action) => {
      state.title = action.payload;
    },
    /**
     * Action used to change validation status.
     */
    changeValidationStatus: (state, action) => {
      const {
        payload: { isValidated = false },
      } = action;
      state.isValidated = isValidated;
    },
    changeStartDate: (state, action) => {
      state.startDate = action.payload;
      state.isValidated = false;
    },
    changeEndDate: (state, action) => {
      state.endDate = action.payload;
      state.isValidated = false;
    },
    changeBalance: (state, action) => {
      state.balance = action.payload;
      state.isValidated = false;
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload;
      state.isValidated = false;
    },
    /**
     * Action used to change the save status of the robot.
     */
    changeSaveStatus: (state, action) => {
      const {
        payload: { id, saveStatus, isShared },
      } = action;
      if (id) state.id = id;
      state.isSaved = saveStatus;
      if (typeof isShared !== "undefined") {
        state.isShared = isShared;
      }
    },
    changeImage: (state, action) => {
      state.image = action.payload;
    },
    changeValidation: (state, action) => {
      const { payload } = action;
      state.gain = payload.gain;
      state.tradesWon = payload.trades_win;
      state.tradesLost = payload.trades_loss;
      state.successRate = payload.success;
      state.gainsOnly = payload.gains;
      state.losesOnly = payload.loses;
      state.gainLossRate = payload.gain_loss_rate;
      state.bestGain = payload.best;
      state.worstLoss = payload.wost;
      state.drawdown = payload.drawdown;
      state.relativeDrawdown = payload.relativeDrawdown;
      state.runup = payload.runup;
      state.relativeRunup = payload.relativeRunup;
      state.image = payload.image;
      state.isValidated = true; // Robot is now validated
    },
  },
});

export const {
  reset,
  loadRobot,
  changeTitle,
  changeStartDate,
  changeEndDate,
  changeBalance,
  changeCurrency,
  changeSaveStatus,
  changeValidation,
  changeValidationStatus,
  changeImage,
} = robotSlice.actions;

export default robotSlice.reducer;