import { useState, cloneElement } from "react";
import PropTypes from "prop-types";
// Frameworks
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, Fade, Link, Paper } from "@mui/material";
// Assets
import { Lock } from "@mui/icons-material";

const ProtectedComponent = function (props) {
  const { children, isOpen = false, isValid = false, ...remains } = props;

  const [t] = useTranslation("freemium");

  const isValidCount = localStorage.getItem("user_valid_count") > 0;
  const isUserValid = localStorage.getItem("type_user") !== "F";

  // Modal
  const [open, setOpen] = useState(isOpen);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onHandleClick = (e) => {
    if (
      (!isValid && !isUserValid) ||
      (isValid && !isValidCount && !isUserValid)
    ) {
      e.preventDefault();
      e.stopPropagation();
      window.dispatchEvent(new Event("mouseup"));
      handleOpen();
    } else if (remains.onTabClick) {
      remains.onTabClick(e, 0);
    } else if (remains.onItemClick) {
      remains.onItemClick(remains.itemValue);
      window.dispatchEvent(new Event("click"));
    }
  };

  return (
    <>
      {cloneElement(children, {
        onClick: onHandleClick,
        onMouseDown: onHandleClick,
      })}
      {((!isValid && !isUserValid) ||
        (isValid && !isValidCount && !isUserValid)) && (
        <Box
          position="relative"
          width="100%"
          flex="0 0"
          zIndex="1"
          draggable="true"
        >
          <Box
            position="absolute"
            right={remains.iconRight}
            top={remains.iconTop}
          >
            <Lock
              style={{ fontSize: remains.iconSize, color: remains.iconColor }}
            />
          </Box>
        </Box>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableAutoFocus
      >
        <Fade in={open}>
          <Paper sx={{ display: "flex", p: 2, alignItems: "center" }}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Lock style={{ fontSize: 80, color: "grey" }} />
            </Box>
            <Box>
              <Typography id="transition-modal-title" variant="h4">
                {t("action")}
              </Typography>
              <Link variant="h5" href={t("plans_link")} target="_blank">
                {t("improve")}
              </Link>
            </Box>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

ProtectedComponent.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool,
  isValid: PropTypes.bool,
};

export default ProtectedComponent;
