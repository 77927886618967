import type Parameter from "./Parameter";

type ElementConstructor = {
  id: number;
  name: string;
  number: string;
  image: string | null;
  params: Array<Parameter>;
  tickAvailable: boolean;
  disabledElements: number[];
};
export default class Element {
  id: number;

  number: string;

  name: string;

  image: string | null;

  params: Array<Parameter>;

  tickAvailable: boolean;

  disabledElements: number[];

  constructor({
    id,
    name,
    number,
    image = null,
    params = [],
    tickAvailable = true,
    disabledElements = [],
  }: ElementConstructor) {
    this.id = id;
    this.number = number;
    this.name = name;
    this.image = image;
    this.params = params;
    this.tickAvailable = tickAvailable;
    this.disabledElements = disabledElements;
  }
}
