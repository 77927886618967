import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import customAxios from "lib/axios";

interface ValidateHashProps {
  idleMinutes: Number;
}

/**
 * Component used to add an auto log out to the app.
 * @example
 * // Log out after 60 minutes.
 * <AutoLogOut idleMinutes={60} />
 */
const ValidateHash = ({ idleMinutes = 120 }: ValidateHashProps) => {
  const history = useHistory();
  const [idleTime, setIdleTime] = useState(0);
  const hashUserId = localStorage.getItem("user_id");  

  /**
   * Handler that will +1 to idleTime every time it gets executed
   */
  const handleIdle = () => {
    if (hashUserId !== null) { 
        setIdleTime((prevIdleTime) => prevIdleTime + 1);
    } else {
        setIdleTime(0);  
    }
  };

    // Effect to setup idle handler & event listeners
    useEffect(() => {
        const id = setInterval(handleIdle, 60000);    
        // Cleanup function
        return () => {
          clearInterval(id);
        };
      }, []);

  // effect that will check if the user reached max idle time
  useEffect(() => {
    if (hashUserId !== null) { 
        if (idleTime === idleMinutes) {
        setIdleTime(0);  
        customAxios
        .post("/users/validateHash", { hashUserId })
        .then((res) => {
            if (res.data === true) {
            const validate = true;
            } else {
                localStorage.removeItem("user_id");
                history.push("/");
            }
        });


        }
    }
  }, [idleTime, idleMinutes, history]);

  return null;
};

export default ValidateHash;