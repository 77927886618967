import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const tradeasyApi = createApi({
  reducerPath: "tradeasyApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_TRADEASY_API }),
  endpoints: (builder) => ({
    getAllTickers: builder.query({
      query: () => `tickers/`,
    }),
    getTicker: builder.query({
      query: (id) => `tickers/${id}`,
    }),
    getAllTimeframes: builder.query({
      query: () => `timeframes/`,
    }),
    getTimeframe: builder.query({
      query: (id) => `timeframes/${id}`,
    }),
    getUserSummary: builder.query({
      query: (id) => `strategies/user/${id}/summary`,
    }),
    getAllSharedStrategies: builder.query({
      query: () => "sharedStrategies/",
    }),
    getNews: builder.query({
      query: () => "news/",
    }),
    getUserStrategies: builder.query({
      query: (id) => `strategies/user/${id}`,
    }),
    getAllSharedRobots: builder.query({
      query: () => "sharedRobots/",
    }),
    getUserRobots: builder.query({
      query: (id) => `robots/user/${id}`,
    }),
  }),
});

//para importar datos
export const {
  useGetAllTimeframesQuery,
  useGetTickerQuery, //Esto son activos
  useGetAllTickersQuery, //Esto son activos
  useGetTimeframeQuery,
  useGetAllSharedStrategiesQuery, //Esto son estrategias compartidas
  useGetUserSummaryQuery,
  useGetNewsQuery,
  useGetUserStrategiesQuery, //Esto son estrategias del usuario
  useGetAllSharedRobotsQuery,
  useGetUserRobotsQuery,
} = tradeasyApi;
