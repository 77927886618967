import SvgIcon from "@mui/material/SvgIcon";

const BuilderIcon = function () {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <path d="M7 5.99998H2.99999C2.72385 5.99998 2.49998 5.77612 2.5 5.49998V3.49999C2.5 3.22385 2.72385 3 2.99999 3H7C7.27614 3 7.49999 3.22385 7.49999 3.49999V5.49998C7.49999 5.77612 7.27614 5.99998 7 5.99998ZM8.99999 5.99998H13C13.2761 5.99998 13.5 5.77612 13.5 5.49998V3.49999C13.5 3.22385 13.2761 3 13 3H8.99999C8.72385 3 8.5 3.22385 8.5 3.49999V5.49998C8.49998 5.77612 8.72385 5.99998 8.99999 5.99998ZM5.99999 6.99998C5.72385 6.99998 5.5 7.22384 5.5 7.49998V9.49997C5.5 9.77611 5.72385 9.99996 5.99999 9.99996H10C10.2761 9.99996 10.5 9.77611 10.5 9.49997V7.49998C10.5 7.22384 10.2761 6.99998 10 6.99998H5.99999ZM13 11H8.99999C8.72385 11 8.5 11.2239 8.5 11.5V13.5C8.5 13.7761 8.72385 14 8.99999 14H13C13.2761 14 13.5 13.7761 13.5 13.5V11.5C13.5 11.2239 13.2761 11 13 11ZM2.99999 11H6.99998C7.27612 11 7.49999 11.2239 7.49999 11.5V13.5C7.49999 13.7761 7.27614 14 7 14H2.99999C2.72385 14 2.5 13.7761 2.5 13.5V11.5C2.5 11.2239 2.72385 11 2.99999 11ZM12 6.99998C11.7239 6.99998 11.5 7.22384 11.5 7.49998V9.49997C11.5 9.77611 11.7239 9.99996 12 9.99996H14V6.99998H12ZM4.50001 7.49999V9.49998C4.50001 9.77612 4.27613 9.99998 3.99999 10H2V7H4.00002C4.27616 7 4.50001 7.22385 4.50001 7.49999Z" />
    </SvgIcon>
  );
};

export default BuilderIcon;
