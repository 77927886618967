import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
// Frameworks
import {
  Box,
  Modal,
  Fade,
} from "@mui/material";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import useAppDispatch from "hooks/useAppDispatch";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

const AlertModal = function (props) {
  const localUserId = localStorage.getItem("user_id");
  const dispatch = useAppDispatch();
  const isUserLoggedIn =
    localUserId && !Number.isNaN(localStorage.getItem("user_id"));
  const [t] = useTranslation(["tradingRobots"]);
  const [showPassword, setShowPassword] = useState(false);
  const [server, setServer] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={3}
          py={1}
          width={310}
          // height={240}
          borderRadius="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          color="white"
          textAlign="center"
          lineHeight="1.2"
        >
        <ReportGmailerrorredIcon sx={{
            // position: "absolute",
            fontSize: "70px",
            // color: "#5db0fe",
            border: "2px",
            backgroundColor: 'rgba(255,255,255, 0.1)',
            borderRadius: "100%",
            padding: "7px",
            marginTop: "20px"
          }} />
         {/* <ReportGmailerrorredIcon textAlign="center"/> */}
         <h3>{props.msgtext}</h3>
          <Box display="flex" width={1} justifyContent={props.alerttype==="okcancel"?"space-between":"center"} mb={2}>
            { props.alerttype==="okcancel" &&
              <LoadingButton
                onClick={()=>props.okayHandler(props.param)}
                variant="contained"
                sx= {{ width: "120px"}}
              >
                {props.okaybtnText}
              </LoadingButton>
            }
            <LoadingButton
              onClick={props.cancelHandler}
              variant="contained"
              sx= {{ width: "120px"}}
            >
              {props.cancelbtnText}
            </LoadingButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
AlertModal.propTypes = {
  param: PropTypes.number,
  alerttype: PropTypes.string,
  msgtext: PropTypes.string,
  okaybtnText: PropTypes.string,
  cancelbtnText: PropTypes.string,
  okayHandler: PropTypes.func,
  cancelHandler: PropTypes.func
}

export default AlertModal;
